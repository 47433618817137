import React from "react";
import { cn } from "utils/css";
import Modal from "./Modal";

const WarningModal = ({
  title,
  description,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm",
  onConfirm,
  onCancel,
  saving,
  hideWarningIcon = false,
  variant = "red",
}) => {
  return (
    <Modal>
      <Modal.Body>
        <div className="sm:flex sm:items-start">
          {!hideWarningIcon && (
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <Modal.Icons.Warning />
            </div>
          )}
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              className="text-gray-900 text-base font-medium leading-6"
              id="modal-title"
            >
              {title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray">{description}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button
          loading={saving}
          onClick={onConfirm}
          className={cn("text-white", {
            "border-red-600 bg-red-600": variant === "red",
            "border-green bg-green": variant === "green",
          })}
        >
          {confirmButtonLabel}
        </Modal.Button>
        <Modal.Button onClick={onCancel}>{cancelButtonLabel}</Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
