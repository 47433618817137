import React from "react";
import Tile from "./Tile";
import cams from "../../img/3d-camera.png";

const CamerasTile = () => {
  return (
    <Tile name="Cameras" square link="/room-control/cameras">
      <img className="h-full w-full object-contain" src={cams} alt="Cameras" />
    </Tile>
  );
};

export default CamerasTile;
