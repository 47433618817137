import React from "react";
import ScrollLock from "components/common/ScrollLock";
import { cn } from "utils/css";

const blurContext = React.createContext();

export function ProvideScreenBlur({ children: content }) {
  const blurContent = useProvideScreenBlur();

  return (
    <blurContext.Provider value={blurContent}>
      <div
        className={cn("absolute inset-0 transition duration-200", {
          "z-30 backdrop-blur-xl": blurContent.screenIsBlurred,
          "-z-50 backdrop-blur-none": !blurContent.screenIsBlurred,
        })}
        onScroll={(e) => {
          if (blurContent.screenIsBlurred) e.preventDefault();
        }}
        onClick={(e) => {
          if (blurContent.screenIsBlurred) e.preventDefault();
        }}
      />
      {blurContent.screenIsBlurred && <ScrollLock />}
      {content}
    </blurContext.Provider>
  );
}

/**
 * Blurs the entire screen when called.
 * Anything above zIndex 30 will be visible.
 * @returns {Object} blurContext
 * @returns {boolean} blurContext.screenIsBlurred
 * @returns {function} blurContext.blurScreen
 * @returns {function} blurContext.unblurScreen
 * @returns {function} blurContext.toggleScreenBlur
 * @example
 * const { screenIsBlurred, blurScreen, unblurScreen, toggleScreenBlur } = useScreenBlur();
 *
 * const handleBlur = () => {
 *  blurScreen();
 * }
 */
export const useScreenBlur = () => {
  return React.useContext(blurContext);
};

const useProvideScreenBlur = () => {
  const [blur, setBlur] = React.useState(false);
  return {
    screenIsBlurred: blur,
    blurScreen: () => setBlur(true),
    unblurScreen: () => setBlur(false),
    toggleScreenBlur: () => setBlur(!blur),
  };
};
