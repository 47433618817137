import React from "react";
import Tile from "./Tile";
import bullhorn from "../../img/3d-bullhorn.png";

const PaSystemTile = () => {
  return (
    <Tile name="PA System" square link="/room-control/pa">
      <img
        className="mx-auto h-full w-full max-w-[75%] object-contain"
        src={bullhorn}
        alt="PA System"
      />
    </Tile>
  );
};

export default PaSystemTile;
