import ReactGA from "react-ga4";

const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;

const enableGA = Boolean(measurementId);

if (enableGA) {
  ReactGA.initialize([
    {
      trackingId: measurementId,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    },
  ]);
}

export function sendPageView(path) {
  if (!enableGA) return;
  ReactGA.send({ hitType: "pageview", page: path });
}

export function sendEvent(event) {
  if (!enableGA) return;
  ReactGA.event(event);
}

export function setUserId(userId) {
  if (!enableGA) return;
  ReactGA.set({ userId });
}

const googleAnalytics = {
  sendPageView,
  sendEvent,
  setUserId,
};

export default googleAnalytics;
