import AddToHomeScreen from "components/common/AddToHomeScreen";
import StickyHeader from "components/common/StickyHeader";
import Tiles from "components/Tiles";
import React, { useEffect } from "react";
import FloatingLocks from "./FloatingLocks";

const Home = () => {
  useEffect(() => {
    // if display mode is standalone, request for fullscreen
    if (window.matchMedia("(display-mode: standalone)").matches) {
      try {
        document.documentElement.requestFullscreen();
      } catch (error) {
        console.error(error);
      }
    }
  });

  return (
    <div className="min-h-screen pb-24 min-h-screen-ios">
      <StickyHeader />
      <Tiles />
      <FloatingLocks />
      <AddToHomeScreen />
    </div>
  );
};

export default Home;
