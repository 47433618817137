import React from "react";
import { cn } from "utils/css";
import LoadingSpinner from "./LoadingSpinner";

const Button = ({
  as = "button",
  className = "",
  onClick = () => {},
  children,
  loading = false,
  disabled = false,
  ...rest
}) => {
  const hassBgOverrides = className?.includes("bg-");
  const hasTextOverrides = className?.includes("text-");
  const hasBorderOverrides = className?.includes("border-");

  const hasBgHoverOverrides = className?.includes("hover:bg-");
  const hasTextHoverOverrides = className?.includes("hover:text-");

  const bgCx = hassBgOverrides ? "" : "bg-white";
  const bgHoverCx = hasBgHoverOverrides ? "" : "hover:bg-transparent";
  const textCx = hasTextOverrides ? "" : "text-black";
  const textHoverCx = hasTextHoverOverrides ? "" : "hover:text-white";
  const borderCx = hasBorderOverrides ? "" : "border-white";

  const displayCx = "flex items-center justify-center";

  const baseCx =
    "transition inline-flex py-2 px-4 rounded-sm font-medium border-sm border-2 rounded-sm hover:bg-transparent disabled:opacity-80 disabled:hover:bg-current disabled:hover:text-current";

  let all = [
    ...baseCx.split(" "),
    ...className.split(" "),
    bgCx,
    bgHoverCx,
    textCx,
    textHoverCx,
    borderCx,
    displayCx,
  ];

  if (loading) disabled = true;

  if (disabled) {
    all = all.filter((c) => !c.includes("hover:"));
  }

  const buttonCx = cn(all);
  const El = as;

  return (
    <El className={buttonCx} onClick={onClick} disabled={disabled} {...rest}>
      {children}
      {loading && <LoadingSpinner className="ml-2" width={16} height={16} />}
    </El>
  );
};

export default Button;
