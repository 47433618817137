import React from "react";
import Tile from "./Tile";
import reception from "../../img/3d-reception.png";
import { useAuth } from "hooks/use-auth";
import { roleCanUpdateTickets } from "utils/roles";
import { useTickets } from "hooks/use-tickets";

const ConciergeTile = () => {
  const { currentRole } = useAuth();
  const canUpdate = roleCanUpdateTickets(currentRole);

  const { data } = useTickets({ fromDate: "today" });

  const tickets = data
    .map((t) => {
      return t.tickets;
    })
    .flat()
    .filter(Boolean);

  const openedTickets = tickets?.filter(
    (t) => t.status !== "closed" && t.status !== "solved",
  );
  const activeTickets = openedTickets?.filter(
    (t) => t.scheduleDate && new Date(t.scheduleDate) < new Date(),
  );

  let subtitle =
    activeTickets?.length === 1
      ? "1 ticket"
      : `${activeTickets?.length || 0} tickets`;
  const subtitleClassName =
    openedTickets?.length > 0
      ? "bg-white text-[#1C1E22]"
      : "bg-[#41454E] text-white";

  if (!canUpdate) {
    subtitle = null;
  }

  return (
    <Tile
      name="Concierge"
      subtitle={subtitle}
      subtitleClassName={subtitleClassName}
      link="/concierge"
    >
      <img src={reception} className="mx-auto max-w-[75%]" alt="Concierge" />
    </Tile>
  );
};

export default ConciergeTile;
