import React from "react";

import { ReactComponent as RadioCheckIcon } from "./icons/radio-check.svg";
import { ReactComponent as RadioCheckActiveIcon } from "./icons/radio-check-active.svg";
import { cn } from "utils/css";

const CheckButton = ({ id, active, label, onChange, className }) => {
  return (
    <button
      key={label}
      className={cn(
        "inline-flex w-full items-center justify-between py-4 text-lg font-medium text-white",
        className,
      )}
      onClick={(e) => {
        e.preventDefault();
        onChange(id);
      }}
    >
      <span>{label}</span>
      <span>
        {active ? (
          <RadioCheckActiveIcon className="ml-auto h-6 w-6" />
        ) : (
          <RadioCheckIcon className="ml-auto h-6 w-6" />
        )}
      </span>
    </button>
  );
};

export default CheckButton;
