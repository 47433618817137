import React from "react";
import Tile from "./Tile";
import myClassroom from "../../img/3d-classroom.png";

const MyClassroomTile = () => {
  return (
    <Tile name="My Classroom" link="/room-control">
      <img
        src={myClassroom}
        alt="My Classroom"
        className="mx-auto max-h-48 max-w-[85%]"
      />
    </Tile>
  );
};

export default MyClassroomTile;
