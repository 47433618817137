const prod = ["kohost.io"];
const dev = ["netlify.app"];

export default function getOrganizationId() {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_ORGANIZATION_ID;
  }

  const parts = window.location.host.split(".");

  // ['orgId', 'app', 'kohost', 'io']

  if (parts.length === 4) {
    const tld = parts[3];
    const host = parts[2];
    const domain = `${host}.${tld}`;

    if (prod.includes(domain)) return parts[0];
    if (dev.includes(domain)) return "dev";
  }

  return null;
}

export const demoOrgs = ["edliodemo", "crestrondemo"];
