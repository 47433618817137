import React, { useState } from "react";

import { cn } from "utils/css";
import parsePhoneNumber from "libphonenumber-js";
import { usePasskey } from "hooks/use-passkey";

import Input from "components/common/Forms/Input";
import SubmitButton from "components/common/Forms/SubmitButton";
import CheckButton from "components/common/CheckButton";

import { getCountryCode } from "utils/browser";
import { useSearchParams } from "react-router-dom";

const webauthnerrors = [
  "invalid user credential",
  "ceremony was sent an abort signal",
  "cancelled by the user",
];

const LoginForm = React.forwardRef(
  (
    {
      onLoginSuccess,
      startLogin,
      finishLogin,
      errorMessage: errorMsg,
      onError: setError,
      successMessage: successMsg,
      onSuccess: setSuccess,
    },
    ref,
  ) => {
    const { authenticate: authenticatePasskey } = usePasskey();
    const [data, setData] = useState("");
    const [orgOptions, setOrgOptions] = useState([]);
    const [loginMethodOptions, setLoginMethodOptions] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("");
    const [selectedLoginMethod, setSelectedLoginMethod] = useState("");
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [type, setType] = useState("");

    const [searchParams] = useSearchParams();

    const handleInput = (e) => {
      if (errorMsg) setError("");
      if (successMsg) setSuccess("");
      setData(e.currentTarget.value);
      setSelectedOrg("");
      setOrgOptions([]);
    };

    const reset = () => {
      setData("");
      setError("");
      setSuccess("");
      setLoading(false);
      setValid(false);
      setType("");
      setSelectedOrg("");
      setOrgOptions([]);
      setSelectedLoginMethod("");
      setLoginMethodOptions([]);
    };

    const handleKeys = (e) => {
      if (e.key === "Enter" && handleValidate()) {
        handleSubmit(e);
      }
    };

    const handleSubmitWithoutWebauthn = (e) => {
      e.preventDefault();
      handleSubmit(e);
    };

    const handleSubmit = async (e) => {
      try {
        e.preventDefault();
        setError("");
        setSuccess("");

        if (!type) return handleValidate();

        setLoading(true);

        const payload = {};
        if (type === "email") payload.email = data.toLowerCase();
        if (type === "phone")
          payload.phone = parsePhoneNumber(data, getCountryCode()).number;
        if (selectedOrg) payload.organizationId = selectedOrg;

        const queryObject = Object.fromEntries(searchParams.entries());

        if (selectedLoginMethod)
          queryObject.verificationMethod = selectedLoginMethod;

        const response = await startLogin({
          data: payload,
          query: queryObject,
        });

        if (response.credentialId) {
          const challengeResponse = await authenticatePasskey(response.options);
          await finishLogin({
            data: {
              challengeResponse,
              credentialId: response.credentialId,
              userId: response.userId,
            },
            query: queryObject,
          });
          setError("");
          setLoading(false);
          setOrgOptions([]);
          setSelectedOrg("");
          onLoginSuccess(false);
        } else if (response.message) {
          setLoading(false);
          return setSuccess(response.message);
        }
      } catch (error) {
        if (error.cause?.code === "LOGIN_ORGANIZATION_REQUIRED") {
          const organizations = error.cause.data?.organizations || [];
          setOrgOptions(organizations);
          setError(error.message);
          setLoading(false);
          return;
        }

        if (error.cause?.code === "LOGIN_VERIFICATION_METHOD_REQUIRED") {
          setLoginMethodOptions(error.cause.data?.verificationMethods || []);
          setError(error.message);
          setLoading(false);
          return;
        }

        // if user denies webauthn, reset form
        if (
          error.name === "NotAllowedError" &&
          error.message.includes("webauthn")
        ) {
          reset();
          return;
        }

        setError(error.message);
        setLoading(false);
        setSuccess("");
      }
    };

    const handleValidate = () => {
      if (!data) return false;
      setError("");
      const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

      // get most likely country code based on browser

      const phone = parsePhoneNumber(data, getCountryCode());
      const isPhone = phone?.isValid();
      const isEmail = emailRegex.test(data);
      if (isPhone) {
        const isUS = phone.country === "US";
        setData(isUS ? phone.formatNational() : phone.formatInternational());
        setValid(true);
        setType("phone");
        return true;
      } else if (isEmail) {
        setValid(true);
        setType("email");
        return true;
      }

      if (!isPhone && !isEmail) {
        setError("Invalid Phone Number or Email");
        setValid(false);
        return false;
      }

      return false;
    };

    const disableButton =
      data === "" ||
      successMsg ||
      loading ||
      (orgOptions.length > 0 && !selectedOrg);

    return (
      <>
        <form onSubmit={handleSubmit} ref={ref} className="w-full">
          <p
            className={cn(
              "rounded-sm transition-all",
              errorMsg || successMsg ? "mb-4 p-4 opacity-100" : "opacity-0",
              errorMsg && "bg-pink",
              successMsg && "bg-green",
            )}
          >
            {errorMsg || successMsg}
            {webauthnerrors.some((err) =>
              errorMsg?.toLowerCase().includes(err),
            ) && (
              <button
                className="ml-2 underline"
                name="skipWebauthn"
                onClick={handleSubmitWithoutWebauthn}
              >
                Send me a link.
              </button>
            )}
          </p>
          <div className="mb-4">
            <Input
              required
              name="data"
              value={data}
              type="text"
              label="Enter Phone Number or Email"
              onChange={handleInput}
              onBlur={handleValidate}
              onKeyUp={handleKeys}
              valid={valid}
              disabled={successMsg || loading}
            />

            <div
              className={cn("px-2 transition-all", {
                "opacity-100":
                  orgOptions.length > 0 || loginMethodOptions.length > 0,
                "opacity-0":
                  orgOptions.length === 0 && loginMethodOptions.length === 0,
              })}
            >
              {orgOptions.map((opt) => {
                return (
                  <CheckButton
                    key={opt.id}
                    id={opt.id}
                    active={selectedOrg === opt.id}
                    label={opt.name}
                    onChange={(id) => setSelectedOrg(id)}
                    className="border-b border-b-[#353941] px-2 last:border-b-0"
                  />
                );
              })}
              {loginMethodOptions.map((opt) => {
                return (
                  <CheckButton
                    key={opt.id}
                    id={opt.id}
                    active={selectedLoginMethod === opt.id}
                    label={opt.name}
                    onChange={(id) => setSelectedLoginMethod(id)}
                    className="border-b border-b-[#353941] px-2 text-white last:border-b-0"
                  />
                );
              })}
            </div>
          </div>

          <SubmitButton
            loading={loading}
            disabled={disableButton}
            label="Login"
          />
        </form>
      </>
    );
  },
);

LoginForm.displayName = "LoginForm";

export default LoginForm;
