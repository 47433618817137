import React from "react";
import { Link } from "react-router-dom";
import CamerasTile from "./CamerasTile";
import CheckOutTile from "./CheckOutTile";
import ConciergeTile from "./ConciergeTile";
import DiningTile from "./DiningTile";
import ElevatorTile from "./ElevatorTile";
import ExperiencesTile from "./ExperiencesTile";
import MyBuildingTile from "./MyBuildingTile";
import MyClassroomTile from "./MyClassroomTile";
import MySuiteTile from "./MySuiteTile";
import RentalsTile from "./RentalsTile";
import ReservationStatusTile from "./ReservationStatusTile";
import SecurityTile from "./SecurityTile";
import SpaTile from "./SpaTile";
import Stats from "./Stats";
import ShopTile from "./StoreTile";
import UsersTile from "./UsersTile";
import YourDayTile from "./YourDayTile";

import { isToday } from "date-fns";
import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";
import { isRoleGreaterThan } from "utils/roles";
import { hasDevices, hasOnlyLock, hasPaSystem } from "utils/space";
import PaSystemTile from "./PASystemTile";

const Tiles = () => {
  const { property, isEducation, isHospitality, isCommercial } = useProperty();

  const isDemo = Boolean(property?.id === "demo");
  const sosFeatureIsEnabled = Boolean(property?.appFeatures?.SOS);
  const {
    spaces,
    upcomingReservations: upcoming,
    checkedInReservations: checkedIn,
    currentRole,
  } = useAuth();
  const { currentSpace } = spaces;

  const allSpaceRooms = spaces?.spaceRooms
    ?.map((query) => query.data)
    ?.flat()
    .filter(Boolean);

  const currentSpaceRooms = currentSpace?.rooms?.data;

  const roleGreaterThanGuest = isRoleGreaterThan(currentRole, "Guest");

  const currentRoleIsGuest = currentRole === "Guest";
  const currentRoleIsSuperAdmin = currentRole === "SuperAdmin";
  const currentRoleIsAdmin = currentRole === "Administrator";
  const currentRoleIsManager = currentRole === "Manager";
  const currentRoleIsMaintenance = currentRole === "Maintenance";

  const onlyHasLock =
    currentSpaceRooms?.every((room) => hasOnlyLock(room)) ||
    (allSpaceRooms.length > 0 &&
      allSpaceRooms?.every((room) => hasOnlyLock(room)));

  const hasRoomControl =
    currentSpaceRooms?.some((room) => hasDevices(room)) ||
    allSpaceRooms?.some((room) => hasDevices(room));
  const hasCameraControl =
    currentSpaceRooms?.some((room) => room.hasCamera) ||
    allSpaceRooms?.some((room) => room.hasCamera);
  const hasAlarmControl =
    currentSpaceRooms?.some((room) => room?.hasAlarm) ||
    allSpaceRooms?.some((room) => room?.hasAlarm);

  const hasPaSystemControl =
    currentSpaceRooms?.some((room) => hasPaSystem(room)) ||
    allSpaceRooms?.some((room) => hasPaSystem(room));

  const checkingOutToday =
    currentRoleIsGuest &&
    checkedIn.some((res) => {
      return res.checkOutDateTime && isToday(new Date(res.checkOutDateTime));
    });

  const propertyTimeTrackingEnabled =
    property?.appFeatures?.Concierge?.timeTracking;

  const showMyStatsTile =
    propertyTimeTrackingEnabled && currentRoleIsMaintenance;
  const showTeamStatsTile =
    propertyTimeTrackingEnabled &&
    (currentRoleIsAdmin || currentRoleIsSuperAdmin);

  const shouldShowMyBuildingTile = () => {
    if (isHospitality) {
      return roleGreaterThanGuest && hasRoomControl && !onlyHasLock;
    }
    if (isCommercial) {
      return hasRoomControl;
    }
  };

  const appFeatures = property?.appFeatures;

  const showUsersTile =
    currentRoleIsSuperAdmin || currentRoleIsAdmin || currentRoleIsManager;

  const showCheckOutTile =
    (appFeatures?.CheckOut && checkingOutToday && currentRoleIsGuest) ||
    (currentRoleIsGuest && isDemo);

  const showMyBuildingTile = shouldShowMyBuildingTile();

  const showReservationStatusTile = Boolean(
    currentRoleIsGuest && upcoming.length,
  );

  const showMySuiteTile = Boolean(
    currentRoleIsGuest && checkedIn.length && hasRoomControl && !onlyHasLock,
  );

  const showMyClassroomTile = isEducation && hasRoomControl;

  const showMyDayTile = currentRoleIsMaintenance;

  const showFullConciegeTile =
    !hasRoomControl &&
    !showMySuiteTile &&
    !showMyBuildingTile &&
    !showMyClassroomTile &&
    !showReservationStatusTile;

  return (
    <div className="container mx-auto bg-dark-gray pl-4 pr-4 backdrop:blur-md">
      {showMyBuildingTile && (
        <FullTileWrapper>
          <MyBuildingTile includeCheckedIn={onlyHasLock} />
        </FullTileWrapper>
      )}
      {showMySuiteTile && (
        <FullTileWrapper>
          <MySuiteTile includeCheckedIn={onlyHasLock} />
        </FullTileWrapper>
      )}

      {showMyClassroomTile && (
        <FullTileWrapper>
          <MyClassroomTile />
        </FullTileWrapper>
      )}

      {showReservationStatusTile && (
        <FullTileWrapper>
          <ReservationStatusTile includeCheckedIn={onlyHasLock} />
        </FullTileWrapper>
      )}

      {showFullConciegeTile && (
        <FullTileWrapper>
          <ConciergeTile />
        </FullTileWrapper>
      )}

      {showMyDayTile && (
        <FullTileWrapper>
          <YourDayTile />
        </FullTileWrapper>
      )}

      <div className="mt-4 grid grid-cols-2 gap-4 lg:mt-8 lg:grid-cols-4 lg:gap-8">
        {hasCameraControl && (
          <SquareTileWrapper>
            <CamerasTile />
          </SquareTileWrapper>
        )}
        {hasAlarmControl && (
          <SquareTileWrapper>
            <SecurityTile />
          </SquareTileWrapper>
        )}
        {hasPaSystemControl && (
          <SquareTileWrapper>
            <PaSystemTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Concierge && !showFullConciegeTile && (
          <SquareTileWrapper>
            <ConciergeTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Elevator && (
          <SquareTileWrapper>
            <ElevatorTile />
          </SquareTileWrapper>
        )}

        {showCheckOutTile && (
          <SquareTileWrapper>
            <CheckOutTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Dining && (
          <SquareTileWrapper>
            <DiningTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Shop && (
          <SquareTileWrapper>
            <ShopTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Spa && (
          <SquareTileWrapper>
            <SpaTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Experiences && (
          <SquareTileWrapper>
            <ExperiencesTile />
          </SquareTileWrapper>
        )}

        {appFeatures?.Rentals && (
          <SquareTileWrapper>
            <RentalsTile />
          </SquareTileWrapper>
        )}

        {showUsersTile && (
          <SquareTileWrapper>
            <UsersTile />
          </SquareTileWrapper>
        )}
      </div>
      {showMyStatsTile && <Stats filter="mine" label="Your ticket stats" />}
      {showTeamStatsTile && <Stats filter="team" label="Team ticket stats" />}

      {sosFeatureIsEnabled && (
        <div className="w-full">
          <SOSButton />
        </div>
      )}
    </div>
  );
};

const SquareTileWrapper = ({ children }) => {
  return (
    <div className="flex aspect-square flex-col items-center justify-center">
      {children}
    </div>
  );
};

const FullTileWrapper = ({ children }) => {
  return <div className="mt-4 first:mt-0">{children}</div>;
};

const SOSButton = () => {
  return (
    <Link
      to="/sos"
      className="mt-8 block w-full touch-none select-none rounded-md border-2 border-alarm-red bg-alarm-red p-2 text-center font-semibold tracking-wider text-white"
    >
      <span
        className={
          "leading-0 mx-auto flex h-10 w-10 items-center justify-center rounded-full border-2 text-xs font-semibold text-white/50"
        }
      >
        <span className="mt-0.5">SOS</span>
      </span>
    </Link>
  );
};

export default Tiles;
