import StarRating from "components/CheckIn/Upgrades/StarRating";
import StyledSelect from "components/common/Forms/StyledSelect";
import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";
import { useTickets } from "hooks/use-tickets";
import { useTimesheets } from "hooks/use-timesheets";
import React from "react";
import { formatDistance } from "utils/dates";

const Stats = ({ filter = "mine", label = "Your ticket stats" }) => {
  const { isHospitality } = useProperty();
  const { data: currentUser } = useAuth();
  const [selectedRange, setSelectedRange] = React.useState("week");

  const { stats: ticketStatsQuery } = useTickets(
    {
      fromDate: selectedRange,
      "assignedTo.userId": filter === "mine" ? currentUser.id : undefined,
    },
    { mine: false, enable: false, includeStats: true },
  );
  const { stats: timeSheetStatsQuery } = useTimesheets(
    {
      fromDate: selectedRange,
      userId: filter === "mine" ? currentUser.id : undefined,
    },
    { includeStats: true },
  );

  const ticketStats = ticketStatsQuery?.data && ticketStatsQuery.data[0];
  const timeSheetStats = timeSheetStatsQuery?.data;

  if (!ticketStats || !timeSheetStats) return null;

  let totalCompleted = ticketStats.resolved + ticketStats.closed;
  totalCompleted = isNaN(totalCompleted) ? 0 : totalCompleted;
  const averageRating = ticketStats.averageRating || 0;
  const now = new Date();
  const nowPlusResolutionTime = new Date(
    now.getTime() + (ticketStats.averageResolutionTimeSeconds || 0) * 1000,
  );

  const resolutionTime = formatDistance(now, nowPlusResolutionTime, {
    includeSeconds: true,
  });
  const totalTips = `$${ticketStats.totalTips}`;

  const totalWorkingHours = secondsToHours(timeSheetStats.totalWorkingSeconds);
  const totalDrivingHours = secondsToHours(timeSheetStats.totalDrivingSeconds);

  let efficiency = Math.round(
    (totalWorkingHours / (totalWorkingHours + totalDrivingHours)) * 100,
  );
  if (isNaN(efficiency)) efficiency = 0;

  return (
    <>
      <div className="mb-2 mt-8 flex items-center justify-between">
        <h3 className="text-2xl font-medium">{label}</h3>
        <StyledSelect
          options={[
            {
              id: "week",
              name: "This week",
            },
            {
              id: "month",
              name: "This month",
            },
            {
              id: "year",
              name: "This year",
            },
          ]}
          selected={selectedRange}
          onChange={(val) => setSelectedRange(val)}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-8">
        <StatTile label="Total completed" value={totalCompleted} />
        <StatTile
          label="Avg. rating"
          value={<StarRating rating={averageRating} />}
        />
        <StatTile label="Avg. time resolved" value={resolutionTime} />
        {isHospitality && <StatTile label="Total tips" value={totalTips} />}
        <StatTile
          label="Total work time"
          value={totalWorkingHours + " hours"}
        />
        <StatTile
          label="Total drive time"
          value={totalDrivingHours + " hours"}
        />
        <StatTile label="Total efficiency" value={efficiency + "%"} />
      </div>
    </>
  );
};

export const StatTile = ({ label, value }) => {
  return (
    <div className="flex aspect-video flex-col justify-center rounded-md bg-light-gray px-3 py-2 shadow-lg sm:px-5 sm:py-4">
      <div className="line-clamp-1 text-sm font-medium sm:text-base">
        {value}
      </div>
      <div className="text-xs text-muted sm:text-sm">{label}</div>
    </div>
  );
};

function secondsToHours(seconds) {
  // convert seconds to hours and round to 1 decimal place
  return Math.round((seconds / 3600) * 10) / 10;
}

export default Stats;
