import React from "react";
import { cn } from "utils/css";
import { Link } from "react-router-dom";

const Tile = ({
  name,
  subtitle,
  className = "",
  subtitleClassName = "",
  children,
  link,
  target = "_self",
}) => {
  const cx = cn(
    "bg-light-gray",
    "rounded",
    "shadow-lg",
    "flex",
    "flex-col",
    "flex-1",
    "justify-between",
    "overflow-hidden",
    "w-full",
    "relative",
    className,
  );

  const Wrapper = link ? Link : "div";

  return (
    <Wrapper className={cx} to={link} target={target}>
      <div className="flex h-5/6 w-full grow-0 flex-col justify-center overflow-hidden p-4">
        {children}
      </div>
      {name && (
        <p className="mb-1 flex h-1/6 shrink-0 items-center justify-between px-2 text-lg font-medium">
          {name}
          {subtitle && (
            <span
              className={cn(
                "rounded-lg px-1 py-0.5 text-xs",
                subtitleClassName,
              )}
            >
              {subtitle}
            </span>
          )}
        </p>
      )}
    </Wrapper>
  );
};

export default Tile;
